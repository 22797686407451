
import { Vue, Component } from "vue-property-decorator";
import { mapGetters } from "vuex";
import ViewSwitch from "@/components/mobile/results-view-switch/index.vue";
import PropertyCard from "@/pages/dream-home-page/ui/property-card/index.vue";

@Component({
  name: "mobile-map-view",
  inject: ["EventBus"],
  computed: {
    ...mapGetters({
      mapCollapsed: "mobile/mapCollapsed",
      mapExpanded: "mobile/mapExpanded",
      selectedPropertyId: "household/selectedPropertyId",
    }),
  },

  components: { ViewSwitch, PropertyCard },
})
export default class extends Vue {
  mapCollapsed!: any;
  mapExpanded!: any;
  selectedPropertyId!: string;
  storeUnsubscribeHandler: any;

  created() {
    this.subscribeToStoreMutatations();
  }

  mounted() {
    this.rerenderMap();
  }

  beforeDestroy() {
    this.unsubscribeStoreMutations();
  }

  get rootClass() {
    return [
      this.$style.root,
      {
        [this.$style.collapsed]: this.mapCollapsed,
        [this.$style.expanded]: this.mapExpanded,
      },
    ];
  }

  get viewSwitchOptions() {
    return [
      { label: "List", value: "list" },
      { label: "Map", value: "map-expanded" },
    ];
  }

  get selectedProperty() {
    return this.$store.getters["property/propertyById"](
      this.selectedPropertyId,
    );
  }

  $rootEl() {
    return this.$refs.root as HTMLElement;
  }

  viewChanged(viewName) {
    this.$store.commit("mobile/setPropertyView", viewName);
  }

  setResult(propertyId) {
    this.$emit("set-result", propertyId);
  }

  checkSelected(propertyId): boolean {
    return this.selectedPropertyId === propertyId;
  }

  rerenderMap() {
    this.$nextTick(() => {
      this.resizeMapContainer();
      this.EventBus.$emit.rerenderMap();
    });
  }

  toggle$rootHeight(viewName) {
    if (["map-collapsed", "map-expanded"].includes(viewName)) {
      this.rerenderMap();
    } else if (viewName === "list") {
      this.set$rootHeight(0);
    }
  }

  resizeMapContainer() {
    let height = this.viewPortHeight();
    const offsetTop = 128;

    if (this.mapCollapsed) {
      height -= this.$rootYPos();
    } else if (this.mapExpanded) {
      const $pageContent = document.querySelector(
        ".page-content",
      ) as HTMLElement;
      $pageContent.scrollTo({
        top: 0,
      });

      height -= offsetTop;
    }
    height = height - this.uiScrollbars();
    this.set$rootHeight(height);
  }

  set$rootHeight(height) {
    this.$rootEl().style.height = height + "px";
  }

  $rootYPos() {
    return this.$rootEl().getBoundingClientRect().top;
  }

  viewPortHeight() {
    return document.body.getBoundingClientRect().height;
  }

  uiScrollbars() {
    return window.screen.availHeight - this.viewPortHeight();
  }

  subscribeToStoreMutatations() {
    this.storeUnsubscribeHandler = this.$store.subscribe((mutation) => {
      if (mutation.type.startsWith("mobile")) {
        switch (mutation.type) {
          case "mobile/setPropertyView":
            this.toggle$rootHeight(mutation.payload);
            break;
        }
      }
    });
  }

  unsubscribeStoreMutations() {
    this.storeUnsubscribeHandler();
  }
}
