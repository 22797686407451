
import { Component, Vue } from "vue-property-decorator";
import MobileMapView from "@/components/mobile/map-view/index.vue";
import LayoutController from "./mixins/layout-controller";

@Component({
  name: "dream-home-wide-layout",
  inject: ["EventBus"],
  mixins: [LayoutController],
  components: { MobileMapView },
})
export default class extends Vue {}
